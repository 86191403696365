import React, { useEffect, useState } from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  useGetList,
  SelectInput,
  useNotify,
  NumberInput,
  TabbedForm,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  DateTimeInput,
  AutocompleteInput,
  FormDataConsumer,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';

const AppCreate = (props: any) => {
  const [accounts, setAccounts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [apps, setApps] = useState([]);

  const notify = useNotify();

  const onSuccess = () => {
    notify(`New App created `);
  };

  const accountsList: any = useGetList(
    'accounts',
    {
      sort: { field: 'login', order: 'DESC' },
    },
  );

  const groupsList: any = useGetList(
    'groupsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  const { data: appsData }: any = useGetList(
    'appsList',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (accountsList.data) {
      setAccounts(accountsList.data?.map((record: any) => ({ id: record._id, name: record.login })));
    }
  }, [accountsList.data]);

  useEffect(() => {
    if (groupsList.data) {
      setGroups(groupsList.data?.map((record: any) => ({ id: record._id, name: record.name })));
      setGroupsData(groupsList.data);
    }
  }, [groupsList.data]);

  useEffect(() => {
    if (appsData) {
      setApps(appsData?.map((record: any) => ({ id: record._id, name: record.name })));
    }
  }, [appsData]);

  return (
    <Create
      title='Create App' {...props}
      onSuccess={onSuccess}
    >
      <TabbedForm>
        <TabbedForm.Tab
          label='App data'
        >
          <Grid container sx={{ pl: '10px', pr: '30px' }}>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='appName' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='appPackage' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={1.5} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  choices={[
                    { id: 'Android', name: 'Android' },
                    { id: 'iOS', name: 'iOS' }
                  ]}
                  source='os'
                  label='OS'
                  isRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }}>
                <NumberInput helperText={false} source='keitaro_id' label='Keitaro ID' sx={{ width: '100%' }} />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={4.15} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='url' label='Google Play App Url' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2.66} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='tg_img' label='Telegram Image Url' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2.3} sx={{ display: 'flex' }} >
                <AutocompleteInput
                  helperText={false}
                  source='fallbackApp'
                  choices={apps}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput helperText={false} source='category' isRequired sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='vertical'
                  choices={[
                    { id: 'Gambling', name: 'Gambling' },
                    { id: 'Betting', name: 'Betting' },
                    { id: 'Dating', name: 'Dating' },
                    { id: 'Finance', name: 'Finance' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1.62} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='promotionType'
                  choices={[
                    { id: 'Naming', name: 'Naming' },
                    { id: 'Deep Link', name: 'Deep Link' },
                    { id: 'Naming + Deep Link', name: 'Naming + Deep Link' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2.7} sx={{ display: 'flex' }} >
                <SelectArrayInput
                  helperText={false}
                  source='source'
                  choices={[
                    { id: 'Facebook', name: 'Facebook' },
                    { id: 'UAC', name: 'UAC' },
                    { id: 'Unity', name: 'Unity' },
                    { id: 'TikTok', name: 'TikTok' },
                    { id: 'OneLink', name: 'OneLink' },
                    { id: 'In-app', name: 'In-app' }
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }} >
                <NumberInput helperText={false} source='ageRating' sx={{ width: '100%' }} />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={7} sx={{ display: 'flex' }} >
                <TextInput
                  source='oneLink'
                  fullWidth
                  helperText={false}
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextInput
                label='Click attribution link'
                source='click_attr'
                fullWidth
                helperText={false}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <TextInput
                label='Impression attribution Link'
                source='impression_attr'
                fullWidth
                helperText={false}
                sx={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', marginTop: '15px' }}>
              <Typography variant="h6">Facebook</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fbAccount'
                  label='FB Account'
                  choices={accounts}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='fbId'
                  label='FB ID'
                  isRequired
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={8} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='post_form'
                  fullWidth
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px' }}>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fb_share_type'
                  choices={[
                    { id: 'changePackage', name: 'Change Package' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <SelectInput
                  helperText={false}
                  source='fb_share_time'
                  choices={[
                    { id: '5', name: '5' },
                    { id: '10', name: '10' },
                    { id: '20', name: '20' },
                    { id: '30', name: '30' },
                    { id: '60', name: '60' },
                  ]}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={8} sx={{ display: 'flex' }} >
                <TextInput
                  helperText={false}
                  source='post_form_app'
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', columnGap: '20px', marginTop: '30px' }}>
              <Grid item xs={1.5} sx={{ display: 'flex', paddingTop: '13px' }} >
                <BooleanInput
                  helperText={false}
                  source='bot_available'
                  label='Available in bot'
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex' }} >
                <DateTimeInput
                  helperText={false}
                  source='issueDate'
                  label='Connection Date'
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex' }} >
                <NumberInput
                  helperText={false}
                  source='weight'
                  label='App weight'
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabbedForm.Tab>

        <TabbedForm.Tab
          label='Google ADS Data'>
          <TextInput
            source='google_ads_identifier'
            label='Identifier'
            fullWidth
            helperText={false}
          />
          <ArrayInput {...props} source='google_ads_rk' label='RK IDs'>
            <SimpleFormIterator
              disableReordering
              getItemLabel={index => `#${index + 1}`}
              sx={{
                '& .RaSimpleFormIterator-list': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  // flexDirection: 'column'
                },
                '& .RaSimpleFormIterator-line': {
                  borderBottom: '0',
                  // width: '20%',
                },
                '& .RaSimpleFormIterator-form': {
                  // display: 'flex',
                  flexDirection: 'row'
                }
              }}
            >
              <TextInput
                resettable
                source='rk_id'
                label='ID'
                sx={{
                  marginRight: '20px'
                }}
              />
              <AutocompleteInput
                // resettable
                label='Team'
                source='rk_team'
                choices={groups}
                optionText="name"
                optionValue="name"
                sx={{
                  width: '200px'
                }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab
        >

        <TabbedForm.Tab
          label='Access Management'
        >
          <ArrayInput {...props} source='groups' label='Groups'>
            <SimpleFormIterator
              disableReordering
              getItemLabel={index => `#${index + 1}`}
              sx={{
                '& .RaSimpleFormIterator-line': {
                  borderBottom: '0',
                },
                '& .RaSimpleFormIterator-form': {
                  flexDirection: 'row'
                }
              }}
            >
              <AutocompleteInput
                sx={{ minWidth: '250px', mr: '10px' }}
                source='group'
                label='Select group'
                choices={groups}
              />
              <FormDataConsumer>
                {({ scopedFormData, getSource, ...rest }: any) => {
                  let users: any[];
                  // @ts-ignore
                  if (scopedFormData.group) {
                    // @ts-ignore
                    const { mates }: any = groupsData
                      .find((group: any) => (group.id === scopedFormData.group));

                    users = mates
                      .map((user: string) => ({ id: user, name: user }));
                  } else {
                    users = [];
                  }
                  return (
                    <AutocompleteArrayInput
                      sx={{ minWidth: '300px' }}
                      label='Select users'
                      source={getSource('users')}
                      choices={users}
                      {...rest}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default AppCreate;
